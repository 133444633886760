a {
    text-decoration: none;
    color: inherit;
}

body,
#app {
    font-family: 'IBM Plex Sans', Arial, sans-serif;
    font-size: 16px;
    line-height: 1.43;
    opacity: 1;
    overflow-x: hidden;
}

.previewBox {
    overflow: hidden;
    margin: 0;
}

.recharts-responsive-container .recharts-wrapper,
.recharts-responsive-container .recharts-surface {
    overflow: inherit
}

/* removes search default styles */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.search_match {
    font-weight: 600;
    background-color: #FEF08A;
}
body .search_match.focused {
    background-color: #F7CA5F;
}
